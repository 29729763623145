// IIFE called when script is loaded
// Immediately invoked function expression
( function initModule() {
    const tableAccordions = [...document.querySelectorAll('.table-accordion')];

    tableAccordions.forEach( table => {
        const accordionTrsVisible = [...table.querySelectorAll('.js-table-accordion-row-has-content')];

        accordionTrsVisible.forEach( tr => {
            const trLink = tr.querySelector('.link-table-accordion');
            const trTarget = table.querySelector(`#${tr.dataset.tableRowTarget}`);

            trLink.addEventListener('click', (e) => {
                e.preventDefault();

                if( trTarget.classList.contains('is-open') ) {
                    tr.classList.remove('is-open');
                    trTarget.classList.remove('is-open');
                    trTarget.classList.add('is-hidden');
                } else {
                    tr.classList.add('is-open');
                    trTarget.classList.add('is-open');
                    trTarget.classList.remove('is-hidden');
                }

            });
        } );
    } );
} )();
